import { IForm } from '@/base-ui/form'

export const modalConfig: IForm = {
  title: '新建运单(*号代表必填！)',
  formItems: [
    {
      field: 'oddnumber',
      type: 'input',
      label: '运单号:',
      placeholder: '请输入运单号',
      prop: 'oddnumber'
    },
    {
      field: 'billingAt',
      type: 'datepicker',
      label: '开单时间:',
      otherOptions: {
        placeholder: '选择日期'
      },
      prop: 'billingAt'
    },
    {
      field: 'consignee',
      type: 'autocomplete',
      label: '收货人:',
      placeholder: '请输入收货人',
      prop: 'consignee'
    },
    {
      field: 'consigneephone',
      type: 'input',
      label: '收货人号码:',
      placeholder: '请输入收货人号码',
      prop: 'consigneephone'
    },
    {
      field: 'address',
      type: 'input',
      label: '收货地址:',
      placeholder: '请输入收货地址',
      prop: 'address'
    },
    {
      field: 'method',
      type: 'select',
      label: '送货方式:',
      placeholder: '请选择送货方式',
      options: [
        { title: '送货', value: '送货' },
        { title: '自提', value: '自提' }
      ],
      prop: 'method'
    },
    {
      field: 'goodsname',
      type: 'input',
      label: '货物名称:',
      placeholder: '请输入货物名称',
      prop: 'goodsname'
    },
    {
      field: 'number',
      type: 'input',
      label: '货物数量:',
      placeholder: '请输入货物数量',
      prop: 'number'
    },
    {
      field: 'pack',
      type: 'select',
      label: '选择包装:',
      placeholder: '请选择包装',
      options: [
        { title: '纸箱', value: '纸箱' },
        { title: '桶', value: '桶' },
        { title: '托', value: '托' },
        { title: '木架', value: '木架' },
        { title: '木箱', value: '木箱' },
        { title: '纤袋', value: '纤袋' },
        { title: '铁', value: '铁' },
        { title: '无包装', value: '无包装' }
      ],
      prop: 'pack'
    },
    {
      field: 'weight',
      type: 'input',
      label: '货物重量(KG):',
      placeholder: '请输入货物重量'
    },
    {
      field: 'measurement',
      type: 'input',
      label: '货物体积(m³):',
      placeholder: '请输入货物体积'
    },
    {
      field: 'cainsurance',
      type: 'select',
      label: '是否参保:',
      placeholder: '请选择是否参保',
      options: [
        { title: '是', value: '是' },
        { title: '否', value: '否' }
      ],
      prop: 'cainsurance'
    },
    {
      field: 'value',
      type: 'input',
      label: '声明价值:',
      placeholder: '请输入声明价值'
    },
    {
      field: 'insurance',
      type: 'input',
      label: '保险费:',
      placeholder: '请输入保险费'
    },
    {
      field: 'consignor',
      type: 'autocomplete',
      label: '发货人:',
      placeholder: '请输入发货人',
      prop: 'consignor'
    },
    {
      field: 'consignorphone',
      type: 'input',
      label: '发货人号码:',
      placeholder: '请输入发货人号码'
    },
    {
      field: 'freight',
      type: 'input',
      label: '运费(元):',
      placeholder: '请输入运费',
      prop: 'freight'
    },
    {
      field: 'delivery',
      type: 'input',
      label: '送货费(元):',
      placeholder: '请输入送货费'
    },
    {
      field: 'sumfreight',
      type: 'input',
      label: '合计运费(元):',
      placeholder: '请输入合计运费',
      prop: 'sumfreight'
    },
    {
      field: 'freightstate',
      type: 'select',
      label: '付款方式:',
      placeholder: '请选择付款方式',
      options: [
        { title: '现付', value: '现付' },
        { title: '到付', value: '到付' },
        { title: '回付', value: '回付' },
        { title: '月结', value: '月结' }
      ],
      prop: 'freightstate'
    },
    {
      field: 'paynow',
      type: 'input',
      label: '现付:',
      placeholder: '请输入现付金额'
    },
    {
      field: 'paygo',
      type: 'input',
      label: '到付:',
      placeholder: '请输入到付金额'
    },
    {
      field: 'payback',
      type: 'input',
      label: '回付:',
      placeholder: '请输入回付金额'
    },
    {
      field: 'paymonth',
      type: 'input',
      label: '月结:',
      placeholder: '请输入月结金额'
    },
    {
      field: 'receiptnum',
      type: 'input',
      label: '回单数量:',
      placeholder: '请输入回单数量(没有填0)',
      prop: 'receiptnum'
    },
    {
      field: 'company',
      type: 'select',
      label: '发货单位:',
      placeholder: '请选择发货单位',
      options: [],
      prop: 'company'
    },
    {
      field: 'remarks',
      type: 'input',
      label: '备注:',
      placeholder: '请输入备注(没有可不填)'
    }
  ],
  labelWidth: '108px'
}
