// 验证规则
export const rules = {
  oddnumber: [
    {
      required: true,
      message: '账号不能为空！',
      trigger: 'blur'
    }
  ],
  billingAt: [
    {
      required: true,
      message: '开单时间不能为空！',
      trigger: 'blur'
    }
  ],
  consignee: [
    {
      required: true,
      message: '收货人不能为空！',
      trigger: 'blur'
    }
  ],
  address: [
    {
      required: true,
      message: '收货地址不能为空！',
      trigger: 'blur'
    }
  ],
  method: [
    {
      required: true,
      message: '送货方式不能为空！',
      trigger: 'blur'
    }
  ],
  goodsname: [
    {
      required: true,
      message: '货物名称不能为空！',
      trigger: 'blur'
    }
  ],
  number: [
    {
      required: true,
      message: '货物数量不能为空！',
      trigger: 'blur'
    }
  ],
  pack: [
    {
      required: true,
      message: '货物包装不能为空！',
      trigger: 'blur'
    }
  ],
  cainsurance: [
    {
      required: true,
      message: '是否参保不能为空！',
      trigger: 'blur'
    }
  ],
  consignor: [
    {
      required: true,
      message: '发货人不能为空！',
      trigger: 'blur'
    }
  ],
  freight: [
    {
      required: true,
      message: '运费不能为空！',
      trigger: 'blur'
    }
  ],
  sumfreight: [
    {
      required: true,
      message: '合计运费不能为空！',
      trigger: 'blur'
    }
  ],
  freightstate: [
    {
      required: true,
      message: '付款方式不能为空！',
      trigger: 'blur'
    }
  ],
  receiptnum: [
    {
      required: true,
      message: '回单数量不能为空！',
      trigger: 'blur'
    }
  ],
  company: [
    {
      required: true,
      message: '发货单位不能为空！',
      trigger: 'blur'
    }
  ]
}
