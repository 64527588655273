
import { defineComponent, computed } from 'vue'
import { useStore } from '@/store'
import { searchFormConfig } from './config/search.config'
import { contentTableConfig } from './config/content.config'
import { modalConfig } from './config/modal.config'
import { rules } from './config/modal.rules.config'

import PageSearch from '@/components/page-search'
import PageContent from '@/components/page-content'
import PageModal from '@/components/page-modal'

import { usePageSearch } from '@/hooks/usePageSearch'
import { usePageModal } from '@/hooks/usePageModal'
import { useExportData } from '@/hooks/useExportData'

export default defineComponent({
  name: 'orders',
  components: {
    PageSearch,
    PageContent,
    PageModal
  },
  setup() {
    const newCallback = () => {
      modalConfig.title = '新建运单(*号代表必填)'
    }
    const editCallback = () => {
      modalConfig.title = '编辑运单'
    }
    const seeCallback = () => {
      modalConfig.title = '查看运单'
    }
    const [pageContentRef, handleResetClick, handleQueryClick] = usePageSearch()
    const [
      pageModalRef,
      defaultInfo,
      handleSeeData,
      handleNewData,
      handleEditData
    ] = usePageModal(newCallback, editCallback, seeCallback)

    // 动态添加发货公司列表
    const store = useStore()
    const ModalConfigRef = computed(() => {
      const companyItem = modalConfig.formItems.find(
        (item) => item.field === 'company'
      )
      const companySearch = searchFormConfig.formItems.find(
        (item) => item.field === 'company'
      )
      companyItem!.options = store.state.entireCompany.map((item) => {
        return { title: item.name, value: item.name }
      })
      companySearch!.options = store.state.entireCompany.map((item) => {
        return { title: item.name, value: item.name }
      })
      return modalConfig
    })

    // 导出Excel
    const [exportData, handleExportData] = useExportData()
    return {
      searchFormConfig,
      contentTableConfig,
      ModalConfigRef,
      pageContentRef,
      handleResetClick,
      handleQueryClick,
      modalConfig,
      pageModalRef,
      defaultInfo,
      rules,
      handleSeeData,
      handleNewData,
      handleEditData,
      handleExportData,
      exportData
    }
  }
})
