import { IForm } from '@/base-ui/form'

export const searchFormConfig: IForm = {
  formItems: [
    {
      field: 'oddnumber',
      type: 'input',
      label: '运单号:',
      placeholder: '请输入运单号'
    },
    {
      field: 'consignee',
      type: 'input',
      label: '收货人:',
      placeholder: '请输入收货人'
    },
    {
      field: 'consigneephone',
      type: 'input',
      label: '收货人号码:',
      placeholder: '请输入收货人号码'
    },
    {
      field: 'consignor',
      type: 'input',
      label: '发货人:',
      placeholder: '请输入发货人'
    },
    {
      field: 'consignorphone',
      type: 'input',
      label: '发货人号码:',
      placeholder: '请输入发货人号码'
    },
    {
      field: 'number',
      type: 'input',
      label: '货物数量:',
      placeholder: '请输入货物数量'
    },
    {
      field: 'company',
      type: 'select',
      label: '发货单位:',
      placeholder: '请选择发货单位',
      options: [
        { title: '中运', value: '中运' },
        { title: '其他', value: '其他' }
      ]
    },
    {
      field: 'createAt',
      type: 'datepicker',
      label: '开单时间:',
      otherOptions: {
        startPlaceholder: '开始时间',
        endPlaceholder: '结束时间',
        type: 'daterange'
      }
    }
  ]
}
