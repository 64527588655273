export const contentTableConfig = {
  title: '订单列表',
  propList: [
    { prop: 'oddnumber', label: '运单号', minWidth: '140' },
    { prop: 'billingAt', label: '开单时间', minWidth: '120' },
    { prop: 'consignee', label: '收货人', minWidth: '100' },
    {
      prop: 'consigneephone',
      label: '收货人号码',
      minWidth: '120'
    },
    {
      prop: 'address',
      label: '收货地址',
      minWidth: '180'
    },
    {
      prop: 'method',
      label: '送货方式',
      minWidth: '100'
    },
    {
      prop: 'goodsname',
      label: '货物名称',
      minWidth: '100'
    },
    {
      prop: 'number',
      label: '货物数量',
      minWidth: '100'
    },
    {
      prop: 'pack',
      label: '货物包装',
      minWidth: '100'
    },
    {
      prop: 'weight',
      label: '货物重量（KG）',
      minWidth: '100'
    },
    {
      prop: 'measurement',
      label: '货物体积（m³）',
      minWidth: '100'
    },
    {
      prop: 'cainsurance',
      label: '是否参保',
      minWidth: '100'
    },
    {
      prop: 'value',
      label: '声明价值',
      minWidth: '100'
    },
    {
      prop: 'insurance',
      label: '保险费',
      minWidth: '100'
    },
    {
      prop: 'consignor',
      label: '发货人',
      minWidth: '100'
    },
    {
      prop: 'consignorphone',
      label: '发货人号码',
      minWidth: '120'
    },
    {
      prop: 'freight',
      label: '运费（元）',
      minWidth: '100'
    },
    {
      prop: 'delivery',
      label: '送货费（元）',
      minWidth: '100'
    },
    {
      prop: 'sumfreight',
      label: '合计运费（元）',
      minWidth: '100'
    },
    {
      prop: 'freightstate',
      label: '付款方式',
      minWidth: '100'
    },
    {
      prop: 'paynow',
      label: '现付（元）',
      minWidth: '100'
    },
    {
      prop: 'paygo',
      label: '到付（元）',
      minWidth: '100'
    },
    {
      prop: 'payback',
      label: '回付（元）',
      minWidth: '100'
    },
    {
      prop: 'paymonth',
      label: '月结（元）',
      minWidth: '100'
    },
    {
      prop: 'receiptnum',
      label: '回单数量',
      minWidth: '100'
    },
    {
      prop: 'company',
      label: '发货单位',
      minWidth: '100'
    },
    {
      prop: 'remarks',
      label: '备注',
      minWidth: '200'
    }
  ],
  showIndexColumn: true,
  showSelectColumn: true
}
