import { parseTime } from '@/utils/parseTime'
import { exportJson2Excel } from '@/utils/ExportExcel'
import { ElNotification } from 'element-plus'

export function useExportData() {
  let ExportData: any
  const exportData = (item: any) => {
    ExportData = item
  }

  const formatJson = (filterVal: any, jsonData: any) => {
    return jsonData.map((v: any) =>
      filterVal.map((j: any) => {
        if (j === 'timestamp') {
          return parseTime(v[j])
        } else {
          return v[j]
        }
      })
    )
  }

  const handleExportData = () => {
    const tHeader = [
      '运单号',
      '开单时间',
      '收货人',
      '收货人手机',
      '收货地址',
      '送货方式',
      '货物名称',
      '件数',
      '包装',
      '重量（KG）',
      '体积（m³）',
      '是否参保',
      '声明价值',
      '保险费',
      '发货人',
      '发货人手机',
      '运费（元）',
      '送货费（元）',
      '合计运费（元）',
      '付款方式',
      '现付',
      '到付',
      '回付',
      '月结',
      '回单数量',
      '发货公司',
      '备注'
    ]
    const filterVal = [
      'oddnumber',
      'billingAt',
      'consignee',
      'consigneephone',
      'address',
      'method',
      'goodsname',
      'number',
      'pack',
      'weight',
      'measurement',
      'cainsurance',
      'value',
      'insurance',
      'consignor',
      'consignorphone',
      'freight',
      'delivery',
      'sumfreight',
      'freightstate',
      'paynow',
      'paygo',
      'payback',
      'paymonth',
      'receiptnum',
      'company',
      'remarks'
    ]
    const list = ExportData
    if (list.length) {
      const data = formatJson(filterVal, list)
      const timestape = new Date().getTime()
      exportJson2Excel(tHeader, data, `运单列表_${timestape}`)
      ElNotification({
        title: '成功',
        message: '导出成功！',
        type: 'success'
      })
    } else {
      ElNotification({
        title: '失败',
        message: '导出失败，请选择需要导出的数据之后再次进行导出！',
        type: 'error'
      })
    }
  }

  return [exportData, handleExportData]
}
